import { type Locale, setDefaultOptions } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LocalChange = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    let locale: Locale = enUS;
    switch (i18n.language) {
      case 'en':
        locale = enUS;
        break;
      case 'fr':
        locale = fr;
        break;
    }

    setDefaultOptions({ locale });
  }, [i18n.language]);
  return null;
};

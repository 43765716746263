import { useRouteLoaderData } from '@remix-run/react';
import { isValidElement, useEffect } from 'react';
import { $routeId } from 'remix-routes';
import { ClientOnly } from 'remix-utils/client-only';
import { cn } from '~/libs/utils';
import type { loader as rootLoader } from '~/root';

import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '~/components/ui/toast';
import { useToast } from '~/components/ui/use-toast';

import { ApplicationIcon } from '~/components/application-icons';

const isReactElement = (value: unknown): value is React.ReactNode => typeof value !== 'object' || isValidElement(value);

export const ToastFromLoader = () => {
  const { toast: notifyToast } = useToast();
  const routeLoaderData = useRouteLoaderData<typeof rootLoader>($routeId('root'));
  const toast = routeLoaderData?.toast;

  useEffect(() => {
    if (toast) {
      const { iconType, ...displayToast } = toast;
      notifyToast({
        ...displayToast,
        icon: iconType ? <ApplicationIcon icon={iconType} /> : null,
      });
    }
  }, [toast, notifyToast]);

  return null;
};

export const Toaster = () => {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      <div className='hidden' data-testid='toast-list' data-toasts={JSON.stringify(toasts)} />
      {toasts.map(({ id, icon, title, text, action, ...props }) => (
        <Toast data-testid='toast' data-toast-variant={props.variant} key={id} {...props}>
          <div className='grid gap-1'>
            {(title || icon) && (
              <ToastTitle className={cn({ 'flex items-baseline gap-2': icon && title })}>
                {icon ? <span data-testid='toast-icon'>{icon}</span> : null}
                {isReactElement(title) ? <h6 data-testid='toast-title-text'>{title}</h6> : null}
              </ToastTitle>
            )}
            {isReactElement(text) && <ToastDescription data-testid='toast-description'>{text}</ToastDescription>}
          </div>
          {action}
          <ToastClose />
        </Toast>
      ))}
      <ToastViewport />
      <ClientOnly>{() => <ToastFromLoader />}</ClientOnly>
    </ToastProvider>
  );
};

import { useLoaderData } from '@remix-run/react';
import type { PropsWithChildren } from 'react';
import type { loader } from '~/root';

export const Env = ({ children }: PropsWithChildren) => {
  const { env } = useLoaderData<typeof loader>();

  return (
    <>
      {children}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.env = ${JSON.stringify(env)};`,
        }}
      />
    </>
  );
};

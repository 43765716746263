import { useLoaderData } from '@remix-run/react';
import { Provider as JotaiProvider } from 'jotai';
import type { PropsWithChildren } from 'react';
import type { loader } from '~/root';

import { Toaster } from '~/components/ui/toaster';

import { Document, Env, LocalChange } from '~/features/providers';
import { ThemeProvider } from '~/features/providers/theme-provider';

export const Providers = ({ children }: PropsWithChildren) => {
  const { theme } = useLoaderData<typeof loader>();

  return (
    <JotaiProvider>
      <ThemeProvider specifiedTheme={theme ?? 'system'}>
        <Document>
          <Env>{children}</Env>
          <Toaster />
          <LocalChange />
        </Document>
      </ThemeProvider>
    </JotaiProvider>
  );
};

import type { LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import { type MetaFunction, Outlet, redirect } from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import * as z from 'zod';
import { logger } from '~/.server';
import { Providers } from '~/features/providers/providers';
import { getSearchParamsOrRedirect } from '~/server/query-string';
import { getToast } from '~/server/toast';
import '~/tailwind.css';
import { isDevelopment } from '~/utils/is-development';

export const meta: MetaFunction = () => [
  { title: 'Invoicify' },
  { property: 'charset', content: 'utf-8' },
  { property: 'viewport', content: 'width=device-width,initial-scale=1' },
];

const searchParamsSchema = z.object({
  error: z.coerce.string().optional(),
  message: z.coerce.string().optional(),
});

export type SearchParams = z.infer<typeof searchParamsSchema>;

const ns = 'root' satisfies Ns;
export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { i18next, session } = context;
  const t = await i18next.getFixedT(request, ns);

  const url = new URL(request.url);
  const { locale } = context;
  const { error, message } = getSearchParamsOrRedirect(request, searchParamsSchema, url.pathname);
  if (error) {
    logger.warn('Redirecting to %s to display error message: [%s]', url.pathname, error);
    context.showToast({
      title: t('toast.error', 'Error'),
      text: error,
      iconType: 'error',
      variant: 'destructive',
    });
    throw redirect(url.pathname);
  }

  if (message) {
    logger.warn('Redirecting to %s to display message: [%s]', url.pathname, message);
    context.showToast({
      title: t('toast.message', 'Message'),
      text: message,
      iconType: 'message',
      variant: 'default',
    });

    throw redirect(url.pathname);
  }

  const toast = getToast(context);
  return json({
    locale,
    theme: session.get('theme') ?? null,
    toast: toast ?? undefined,
    env: {
      SENTRY_DSN: context.env.VITE_SENTRY_DSN,
    },
  });
};

const App = () => (
  <Providers>
    <Outlet />
  </Providers>
);

export { ErrorBoundary } from '~/components/error-boundary';

export default withSentry(App, {
  wrapWithErrorBoundary: true,
  errorBoundaryOptions: {
    showDialog: isDevelopment(),
  },
});

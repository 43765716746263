import type { ToastProps } from '@radix-ui/react-toast';
import type { VariantProps } from 'class-variance-authority';
import { atom, useAtom, useSetAtom } from 'jotai';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import type { ToastActionElement, toastVariants } from '~/components/ui/toast';

const TOAST_LIMIT = 5;
const TOAST_REMOVE_DELAY = 3000;

type ToasterToast = Omit<ToastProps, 'title'> &
  VariantProps<typeof toastVariants> & {
    id: string;
    icon?: ReactNode;
    title?: ReactNode | unknown;
    text?: ReactNode | unknown;
    action?: ToastActionElement;
  };

interface State {
  toasts: ToasterToast[];
}

export type Toast = Omit<ToasterToast, 'id' | 'onOpenChange'>;

const baseCountAtom = atom(0);
const countAtom = atom(null, (get, set, _arg) => {
  const c = get(baseCountAtom);
  const val = (c + 1) % Number.MAX_VALUE;
  set(baseCountAtom, val);
  return val;
});
const toastAtom = atom<State>({ toasts: [] });
const timeoutAtom = atom<Record<string, ReturnType<typeof setTimeout>>>({});

export function useToast() {
  const [state, setToastsValue] = useAtom(toastAtom);
  const setTimeoutValue = useSetAtom(timeoutAtom);
  const setCount = useSetAtom(countAtom);

  const genId = useCallback(() => setCount(0).toString(), [setCount]);

  const addToRemoveQueue = useCallback(
    (toastId: string) => {
      setTimeoutValue(timeoutValue => {
        if (timeoutValue[toastId]) {
          return timeoutValue;
        }

        const timeout = setTimeout(() => {
          setTimeoutValue(t => {
            delete t[toastId];
            return t;
          });
          setToastsValue(state => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (toastId === undefined) {
              return {
                ...state,
                toasts: [],
              };
            }
            return {
              ...state,
              toasts: state.toasts.filter(t => t.id !== toastId),
            };
          });
        }, TOAST_REMOVE_DELAY);

        return { ...timeoutValue, [toastId]: timeout };
      });
    },
    [setTimeoutValue, setToastsValue]
  );

  const dismiss = useCallback(
    (toastId: string | undefined) => {
      setToastsValue(state => {
        // ! Side effects ! - This could be extracted into a dismissToast() action,
        // but I'll keep it here for simplicity
        if (toastId) {
          addToRemoveQueue(toastId);
        } else {
          for (const toast of state.toasts) {
            addToRemoveQueue(toast.id);
          }
        }

        return {
          ...state,
          toasts: state.toasts.map(t =>
            toastId === undefined || t.id === toastId
              ? {
                  ...t,
                  open: false,
                }
              : t
          ),
        };
      });
    },
    [addToRemoveQueue, setToastsValue]
  );

  const toast = useCallback(
    ({ ...props }: Toast) => {
      const id = genId();

      const update = (props: ToasterToast) => {
        setToastsValue(toasts => ({
          ...toasts,
          toasts: toasts.toasts.map(t =>
            t.id === id
              ? {
                  ...t,
                  ...props,
                  id,
                }
              : t
          ),
        }));
      };
      const localDismiss = () => dismiss(id);

      setToastsValue(state => ({
        ...state,
        toasts: [
          {
            ...props,
            id,
            open: true,
            onOpenChange: (open?: boolean) => {
              if (!open) localDismiss();
            },
          } satisfies ToasterToast,
          ...state.toasts,
        ].slice(0, TOAST_LIMIT),
      }));

      return {
        id: id,
        dismiss: localDismiss,
        update,
      };
    },
    [dismiss, genId, setToastsValue]
  );

  return {
    ...state,
    toast,
    dismiss,
  };
}

import { Links, Meta, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';
import {
  useGlobalLoadingState,
  useGlobalPendingState,
  useGlobalSubmittingState,
} from 'remix-utils/use-global-navigation-state';
import { useHydrated } from 'remix-utils/use-hydrated';
import { cn } from '~/libs/utils';
import type { loader } from '~/root';

import { NonFlashOfWrongThemeEls, useTheme } from '~/features/providers/theme-provider';

export const Document = ({ children }: PropsWithChildren) => {
  // Get the locale from the loader
  const data = useLoaderData<typeof loader>();
  const locale = data.locale;
  const { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  const [theme, htmlTheme] = useTheme();
  const hydrated = useHydrated();
  const globalState = useGlobalLoadingState();
  const submittingState = useGlobalSubmittingState();
  const pendingState = useGlobalPendingState();

  return (
    <html
      lang={locale}
      className={cn(theme, htmlTheme)}
      dir={i18n.dir()}
      data-hydrated={hydrated}
      data-is-pending={pendingState !== 'idle'}
      data-is-submitting={submittingState !== 'idle'}
      data-is-loading={globalState !== 'idle'}
    >
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        {hydrated ? <meta name='color-scheme' content={htmlTheme === 'light' ? 'light dark' : 'dark light'} /> : null}
        <Meta />
        <Links />
        <NonFlashOfWrongThemeEls />
      </head>
      <body className='motion-reduce:!transition-none bg-muted text-foreground transition-all duration-75'>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};
